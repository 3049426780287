import { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { Button } from "../../components/Buttons"
import { Cohort } from "../../components/Cohorts"
// import { ConditionalRadio, RadioInput, NumberInput } from "../../components/FormElements"
import { DownloadPreview } from "../../components/Downloads"
import { Alert, Icon, Spacer } from "../../components/GeneralUI"
import { VariableSetsTable } from "../../components/VariableSets"
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks"
import { InnerPage } from "../../layout"

import { getPathFromRouteName, getPlainLangCollections } from "../../utils"
import api from "../../utils/api"
import { NEW_VARIABLE_SET, COLLECTIONS } from "../../utils/constants"

const removeActionProps = {
    variant: "outline",
    iconName: "trash-alt",
    tooltip: "Remove from Download",
}

const addActionProps = {
    tooltip: 'Add to Download',
    iconName: 'plus-circle',
    variant: 'secondary'
}

const cohortSecondaryActions = [
    /* Removing for now per issue #251
    {
        name: 'logic',
        to: `/cohorts/logic/:id`,
        tooltip: 'View Logic',
        iconName: 'expanded-detail',
        fontAwesome: false,
        newTab: true,
    },*/
    {
        name: 'view',
        to: `/cohorts/:id`,
        tooltip: 'View Details',
        iconName: 'table',
        newTab: true
    },
];

export const NewDownload = () => {
    
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText, userPrivs } = useAppState();

    // current state of the UI
    const [ addingVariableSet, setAddingVariableSet ] = useState(false);
    const [ addingCohort, setAddingCohort ] = useState(false)
    //const [ previewLoading, setPreviewLoading ] = useState(false);
    
    // entities to choose from
    const [ cohorts, setCohorts ] = useState([])
    const [ variableSets, setVariableSets ] = useState([])
    
    // chosen entities
    const [ selectedCohort, setSelectedCohortReal ] = useState(null)
    const [ selectedVariableSet, setSelectedVariableSetReal ] = useState(null)
    
    const [ downloadId, setDownloadId ] = useState(null);
    const [ downloadingNow, setDownloadingNow ] = useState(false);

    // Get Cohorts and Variable Sets
    useEffect(() => {

        api.getAllCohorts()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setCohorts(res.data.cohorts)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get All Cohorts.'));
            })

        api.getAllVariableSets()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setVariableSets(res.data.varsets)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Variable Sets.'));
            })  
       
    }, [/*cancelAsyncCall, */errorCheckText, setCohorts, setVariableSets])
    
    // Auto-click the download link once it's rendered.
    const autoclick = useCallback((node) => {
        node?.click()
    }, [])
    
    // Wrappers for setting cohort and varset so that they invalidate any
    // existing preview.
    // FIXME: this might be overkill or inefficient, I'm not sure
    const setSelectedCohort = (value) => {
        if (value !== selectedCohort) {
            setDownloadId(null);
        }
        setSelectedCohortReal(value);
    };
    const setSelectedVariableSet = (value) => {
        if (value !== selectedVariableSet) {
            setDownloadId(null);
        }
        setSelectedVariableSetReal(value);
    };
    
    const handleDownload = () => {
        // We've already created a 'manual download link' below; we just need
        // to turn on downloadingNow (so it renders) then click it.
        setDownloadingNow(true);
    }

    return (
        <InnerPage
            title="Download Data"
            intro={downloadingNow ? "Your download will start automatically." : "A Data Download consists of the data in a Variable Set for those individuals in a Cohort. Columns included are every variable in the selected Variable Set, plus an identifying ID for the individual. (TIP: If you're interested in cell IDs or other genotyping information, you may want to include those variables in your Variable Set!)"}
            sticky
            actions={
                <Button
                    variant="secondary"
                    disabled={!selectedCohort || !selectedVariableSet || !downloadId || downloadingNow }
                    onClick={handleDownload}
                >
                    <span className="btn--text">
                        Download Data <Icon name="download" />
                    </span>
                </Button>
            }
        >

        {downloadingNow ? 
            <p>If for some reason your download does not automatically start after a few seconds, 
                <a
                    href={api.getDataDownloadURI({
                        downloadid: downloadId,
                        filename: `${selectedCohort.name}-${selectedVariableSet.name}.csv`
                    })}
                    ref={autoclick}
                >
                    click here to manually retry
                </a>.</p>
            :
            userPrivs[0] ?
            <>
                <p>You will only be able to download data for individuals in collections for which you have a Data Access Agreement.<br />Our records show you have access to these collections: {getPlainLangCollections(userPrivs)}.<br />To apply for access to a collection, <a href="https://www.nimhgenetics.org/request-access/how-to-request-access">review and follow the application instructions here</a>.</p>
                
                <div>

                      
                    {/* Cohort Selection */}
                    { !selectedCohort ? 
                        (addingCohort ?

                            // Cohorts Table
                            <section className="section--gray mb-4">
                                <Spacer size={2} />


                                <div className="d-flex align-items-center mb-3">
                                    <h3 className="h5 mb-0">All Cohorts</h3>

                                        <Button 
                                            variant="primary" 
                                            size="xs" 
                                            className="ml-3"
                                            onClick={() => setAddingCohort(false)}
                                        >
                                            Close
                                        </Button> 
                                </div>


                                {/* Cohort List */}
                                {
                                    cohorts.map((cohort, i) => (
                                        <Cohort
                                            noDelete
                                            key={i}
                                            cohort={cohort}
                                            actions={{
                                                primary: [{
                                                    ...addActionProps,
                                                    onClick: (cohort) => setSelectedCohort(cohort),
                                                }],
                                                secondary: cohortSecondaryActions
                                            }}
                                        />
                                    ))
                                }
                            </section>
                        :

                            // "Show Cohorts" Button
                            <div className="d-flex my-4 mb-2">

                                <Button
                                    variant="secondary"
                                    onClick={() => { setAddingCohort(true)}}
                                >
                                    <span>Select a Cohort <Icon name="plus-circle" /></span>
                                </Button>
                            
                            </div>

                    )
                        :
                        
                        <section className="mb-5">
                            <h3 className="h5 mt-5">Selected Cohort</h3>

                            <Cohort
                                noDelete
                                cohort={selectedCohort}
                                actions={{
                                    primary: [{
                                        ...removeActionProps,
                                        onClick: () => setSelectedCohort(null),
                                    }],
                                    secondary: cohortSecondaryActions
                                }}
                            /> 
                        </section>

                    }

                    
                    {/* Variable Set Selection */}
                    { !selectedVariableSet ?
                        (addingVariableSet ? 

                            // Variable Sets Table
                            <section className="section--gray">
                                        
                                <Spacer size={2} />

                                <div className="d-flex align-items-center mb-3">

                                    <h3 className="h5 mb-0">All Variable Sets</h3>

                                    <Button 
                                        variant="primary" 
                                        size="xs" 
                                        className="ml-3"
                                        onClick={() => setAddingVariableSet(false)}
                                    >
                                        Close
                                    </Button> 

                                </div>
                                
                                { variableSets.length ?

                                    // Variable Sets List 
                                    <VariableSetsTable 
                                        noDelete
                                        variableSets={variableSets}
                                        actions={[
                                            {
                                                ...addActionProps,
                                                onClick: (set) => setSelectedVariableSet(set),
                                            }
                                        ]}
                                    />
                                :
                                    <Alert variant="warning">
                                        You don't have any variable sets! <Link to={getPathFromRouteName(NEW_VARIABLE_SET)}>Create one here,</Link> then come back and build a download.
                                    </Alert>
                                }
                        
                            </section>
                        :
                            // "Show Variable Sets" Button
                            <div className="d-flex mb-2">
                            
                                    <Button
                                        variant="secondary"
                                        onClick={() => {setAddingVariableSet(true)}}
                                    >
                                        <span>Select a Variable Set <Icon name="plus-circle" /></span>
                                    </Button>
                        
                            
                            </div>
                        
                    )

                    :
                        <section>
                            <h3 className="h5 mt-5">Selected Variable Set</h3>

                            {/* Variable Sets List */}
                            <VariableSetsTable 
                                    noDelete
                                    variableSets={[selectedVariableSet]}
                                    actions={[
                                        {
                                            ...removeActionProps,
                                            onClick: () => setSelectedVariableSet(null),
                                        }
                                    ]}
                                />
                        </section>
                    }
                    
                    <DownloadPreview
                        cohort={selectedCohort}
                        variableSetId={selectedVariableSet?.id}
                        setDownloadIdParent={setDownloadId}
                    />

                </div>
            </>
                :
            <>
                <p>You will only be able to download data for individuals in collections for which you have a Data Access Agreement.</p>
                
                <Alert variant="warning">
                    <span className="fas fa-exclamation-triangle"></span>
                    Our records show that you do not have access to any data collections at this time. You will not be able to Download Data. To apply for access to collections, <a href="https://www.nimhgenetics.org/request-access/how-to-request-access">review and follow the application instructions here</a>.
                </Alert>
            </>
        }
        </InnerPage>
    )
}
