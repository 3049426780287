import { useState, useEffect } from "react";
import { toast } from "react-toastify"
//import PropTypes from "prop-types";

import { TableWrapper } from "../Tables";

import { Button } from "../Buttons"
import { Alert, Icon, Spacer } from "../../components/GeneralUI"
import { getPlainLangCollections } from "../../utils"
import api from "../../utils/api"
import { useAppState } from "../../hooks"

const tableId = "download-preview"

/**
 * Table for displaying a preview of a download
 */
export const DownloadPreview = ({ cohort, variableSetId, setDownloadIdParent }) => {
    
    const { errorCheckText, userPrivs } = useAppState();
    const [ downloadId, setDownloadId ] = useState(null);
    const [ records, setRecords ] = useState([]);
    const [ previewLoading, setPreviewLoading ] = useState(false);
    const [ visibleRecords, setVisibleRecords ] = useState([]);
    
    // This is strictly informative for the user; privs are actually enforced
    // in the middleware API.
    const missingPrivs = userPrivs.includes('admin') ? [] : cohort?.collections.filter( x => !userPrivs.includes(x));
    const havePrivs = userPrivs.includes('admin') ? cohort?.collections : userPrivs.filter( x => cohort?.collections.includes(x));
    
    const updatePreview = () => {
        setPreviewLoading(true);
        api.createDataDownload({
            cohortid: cohort.cohortid,
            varsetid: variableSetId,
        })
        .then(res => {
            setDownloadId(res.data[0].download_id)
        })
        .catch(err => {
            toast.error(errorCheckText(err, 'Unable to generate Download for preview.'));
            setPreviewLoading(false)
        })
        // we let the downloadId useEffect handle actually getting the 
        // downloaded records and stuffing 'em in
    };
    
    useEffect(() => {
        if (downloadId) {
            api.previewDataDownload(downloadId)
            .then(res => {
                setRecords(res.data)
                setDownloadIdParent(downloadId)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to preview Download.'));
            })
            .finally(() => {
                setPreviewLoading(false)
            })
        } else {
            // no valid records; clean out existing
            setRecords([])
            if (setDownloadIdParent) { setDownloadIdParent(null) }
        }
    }, [ downloadId, errorCheckText, setRecords, setDownloadIdParent, setPreviewLoading ]);

    return (
            <section>
                <Spacer size={3} />
                <h3 className="h5">Preview of Records In Download</h3>
                {console.log("missing and owned privs", missingPrivs, havePrivs)}
                { /* notify the user if they're missing access to some collections they would otherwise be downloading */ }
                { cohort ?
                        (!missingPrivs[0]) ||
                        <Alert variant="warning">
                            <span className="fas fa-exclamation-triangle"></span>
                            Your selected cohort contains individuals in the following collections{havePrivs[0] ? ' that you do not' : ', none of which you'} have access to: {getPlainLangCollections(missingPrivs)}.{havePrivs[0] ? <></> : 
                                    <>
                                    <br/>Preview will remain disabled as a result.
                                    </>
                                    }
                            <Spacer size={2} />
                            To apply for access to these collections, <a href="https://www.nimhgenetics.org/request-access/how-to-request-access">review and follow the application instructions here</a>.
                        </Alert>
                    :
                    <></>
                }
                { userPrivs[0] &&
                <TableWrapper rows={records} setVisibleRows={setVisibleRecords} tableId={tableId}>
                    <table className="w-100" id={tableId}>
        
                        {visibleRecords?.length > 0 ? 
                            <>
                            <thead>
                                <tr>
                                    {Object.keys(visibleRecords[0]).map((key, i) => (
                                        <th key={i}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {visibleRecords.map((record, i) => (
                                    <tr key={i}>
                                        {Object.values(record).map((value, j) => (
                                            <td key={j}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            </>
                        :
                            <>    
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="text-center">
                                    <Button
                                        size="md"
                                        disabled={!cohort || !variableSetId || !havePrivs[0]}
                                        variant="secondary"
                                        classes="font-weight-600 flex-grow-1"
                                        onClick={updatePreview}
                                        buttonLoading={previewLoading}
                                    >
                                        <span className="btn--text">
                                            Preview <Icon name="check-circle" />
                                        </span>
                                    </Button>
                                </td>
                                </tr>
                            </tbody>
                            </>
                        }
                    </table>
                </TableWrapper>
                }
            </section>
    )
}


/*DownloadPreview.propTypes = {
    cohort: PropTypes.object,
    variableSetId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    setDownloadIdParent: PropTypes.func,
}*/
