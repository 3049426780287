import { Link, matchPath } from 'react-router-dom';
import { toast } from 'react-toastify';
//import PropTypes from "prop-types";
import { Icon } from '../components/GeneralUI';
import { useAppState } from "../hooks"
import { getPathFromRouteName } from '../utils';
import api from "../utils/api";
import { ALL_COHORTS, ALL_VARIABLES, ASCERTAIN_PEDIGREE, HOME, LIST_PROJECTS, MY_VARIABLE_SETS, /*NEW_CLINICAL_CONSTRUCT, NEW_DOWNCODE, */ ALL_CLINICAL_CONSTRUCTS, NEW_DOWNLOAD, /*NEW_TWO_VAR_CONSTRUCT, NEW_VARIABLE_SET, */ VIEW_PROJECT, PDF_VIEW, ROUTES } from '../utils/constants';
import { QuickLinks, SubNav } from './Navigation';


export const Header = () => {

    const { project, userLoggedIn, clearLoggedInUser, errorCheckText } = useAppState();

    // Main nav items
    const menuItems = [
        {
            to: getPathFromRouteName(ALL_VARIABLES),
            text: <><small className="font-weight-bold d-lg-block">Explore the<span className="d-lg-none"> </span></small>Variables Catalog</>,
        },
        {
            to: getPathFromRouteName(ALL_CLINICAL_CONSTRUCTS),
            text: <><small className="font-weight-bold d-lg-block">Create a<span className="d-lg-none"> </span></small>Custom Variable</>,
            /*childItems: [
                {
                    to: getPathFromRouteName(NEW_DOWNCODE),
                    text: "Downcode a Variable"
                },
                {
                    to: getPathFromRouteName(NEW_TWO_VAR_CONSTRUCT),
                    text: "Combine Two Variables"
                },
                {
                    to: getPathFromRouteName(NEW_CLINICAL_CONSTRUCT),
                    text: "Combine Multiple Variables"
                }
            ]*/
        },
        {
            to: getPathFromRouteName(ALL_COHORTS),
            text: <><small className="font-weight-bold d-lg-block">Build a<span className="d-lg-none"> </span></small>Cohort</>
        },
        {
            to: getPathFromRouteName(ASCERTAIN_PEDIGREE, {id : ''}),
            text: <><small className="font-weight-bold d-lg-block">Ascertain<span className="d-lg-none"> </span></small>Pedigrees</>
        },
        {
            to: getPathFromRouteName(MY_VARIABLE_SETS),
            text: <><small className="font-weight-bold d-lg-block">Assemble a<span className="d-lg-none"> </span></small>Variable Set</>,
            /*childItems: [
                {
                    to: getPathFromRouteName(NEW_VARIABLE_SET),
                    text: "Define a New Variable Set"
                },
                {
                    to: getPathFromRouteName(MY_VARIABLE_SETS),
                    text: "Review My Variable Sets"
                },
            ]*/
        },
        {
            to: getPathFromRouteName(NEW_DOWNLOAD),
            text: <><small className="font-weight-bold d-lg-block">Download<span className="d-lg-none"> </span></small>Data</>
        },
    
    ];
    
    const handleLogout = () => {
        api.logout()
        .then((res) => {
            toast.success(`Successfully logged out.`)
            clearLoggedInUser()
        })
        .catch((err) => {
            toast.error(errorCheckText(err, 'Unable to log out.'))
        });
    };
    
    const goToHelpPage = () => {
        const helpPageNum = ROUTES.filter((item) => matchPath(item.path, window.location.pathname))[0].helpPage;
        const path = getPathFromRouteName(PDF_VIEW, {pdf: "HowToUseDIVER.pdf", pagenum: helpPageNum ? helpPageNum : 1});
        
        window.open(path, '_blank');
    };
    
    const goToHelpTOC = () => {
        const path = getPathFromRouteName(PDF_VIEW, {pdf: "HowToUseDIVER.pdf", pagenum: 1});
        
        window.open(path, '_blank');
    };
        

    // Button nav items
    const quickLinks = [
        {
            to: project ? getPathFromRouteName(VIEW_PROJECT, {id: project.project.id} ) : '',
            text: <>
                {project ? project.project.name : "(Loading)"}
                &nbsp;<Icon name="edit" />
            </>,
            isTextLink: true,
        },
        {
            to: getPathFromRouteName(LIST_PROJECTS),
            text: <>Manage<br/>Projects</>
        },
        {
            onclick: goToHelpTOC,
            text: <>How To<br/>Use DIVER</>,
        },
        {
            onclick: goToHelpPage,
            text: <>Help For<br/>This Page</>,
        },
        /*{ // disabling as the header links to the dashboard
            to: getPathFromRouteName(HOME),
            text: <>DIVER<br/>Dashboard</>
        },*/
        {
            to: getPathFromRouteName(HOME),
            onclick: handleLogout,
            text: <>Log out</>,
            hideIf: window.diverwebUsingExternalAuthForm
        },
    ];
    
    return (
        <section>
            <div className="row">
                
                <div className="col-sm-12 col-lg-6">

                    <h1 className="mb-0"><Link to={getPathFromRouteName(HOME)}>
                        DIVER
                    </Link></h1>

                </div>

                { userLoggedIn &&
                    <div className="col-sm-12 col-lg-6">
                        <QuickLinks items={quickLinks} />
                    </div>
                }

            </div>

            { userLoggedIn && <SubNav menuItems={menuItems} />}

        </section>
    )
}

/*Header.propTypes = {
    loggedIn: PropTypes.bool,
    setLoggedIn: PropTypes.func,
}*/
