//import PropTypes from "prop-types";
import { condClass } from "../../utils/conditionalClass"

/**
 * Renders Bootstrap's "alert" component (not "alert" HTML element)
 */
export const Alert = ({children, variant = "secondary"}) => {
    return (
        <div className={`alert${condClass(variant, 'alert-' + variant)} text-center`}>
            {children}
        </div>
    )
}


/*Alert.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(["primary", "secondary", "info", "warning", "danger"]),
}*/
