// "longname": As it appears in the Data Download Tool.
// "shortname": As it appears in the "by collection" selection in Data Explorer
// "applyname": As it appears on the access request form.
// getPlainLangCollections normally uses applyname; the rest are provided for
// possible future usage.
export const COLLECTIONS = {
    "22q11.2": {
        'longname': "22q11.2 Deletion Syndrome",
        'shortname': "22q11.2",
        'applyname': "22q11.2 Deletion Syndrome",
    },
    "adhd": {
        'longname': "Attention-Deficit Hyperactivity Disorder",
        'shortname': "ADHD",
        'applyname': "ADHD",
    },
    "ad": {
        'longname': "Alzheimer's Disease",
        'shortname': "Alzheimer's Disease",
        'applyname': "Alzheimer's",
    },
    "au": {
        'longname': "Autism",
        'shortname': "Autism",
        'applyname': "Autism",
    },
    "bp": {
        'longname': "Bipolar Disorder",
        'shortname': "Bipolar Disorder",
        'applyname': "Bipolar Disorder",
    },
    "bf": {
        'longname': "Brain Function",
        'shortname': "Brain Function",
        'applyname': "Brain Function Data",
    },
    "ct": {
        'longname': "Controls",
        'shortname': "Controls",
        'applyname': "Controls",
    },
    "dp": {
        'longname': "Depression",
        'shortname': "Depression",
        'applyname': "Depression",
    },
    "ed": {
        'longname': "Eating Disorders",
        'shortname': "Eating Disorder",
        'applyname': "Eating Disorders",
    },
    "fna": {
        'longname': "Fear & Anxiety",
        'shortname': "Fear & Anxiety",
        'applyname': "Fear & Anxiety",
    },
    "ipsc": {
        'longname': "Induced Pluripotent Stem Cells",
        'shortname': "iPSC",
        'applyname': "Induced Pluripotent Stem Cells",
    },
    "ocd": {
        'longname': "Obsessive-Compulsive Disorder, Compulsive Hoarding, & Trichotillomania",
        'shortname': "Obsessive-Compulsive Disorder",
        'applyname': "OCD",
    },
    "ntr": {
        'longname': "Netherlands Twin Register",
        'shortname': "NTR",
        'applyname': "Netherlands Twin Register",
    },
    "pms": {
        'longname': "Phelan-McDermid Syndrome",
        'shortname': "Phelan-McDermid Syndrome",
        'applyname': "Phelan-McDermid Syndrome",
    },
    "ptsd": {
        'longname': "Post Traumatic Stress Disorder",
        'shortname': "PTSD",
        'applyname': "Post Traumatic Stress Disorder",
    },
    "rdoc": {
        'longname': "Research Domain Criteria",
        'shortname': "RDoC",
        'applyname': "Research Domain Criteria (RDoC)",
    },
    "sz": {
        'longname': "Schizophrenia",
        'shortname': "Schizophrenia",
        'applyname': "Schizophrenia",
    },
    "su": {
        'longname': "Suicide",
        'shortname': "Suicide",
        'applyname': "Suicide",
    },
    "ts": {
        'longname': "Tourette's Syndrome",
        'shortname': "Tourette's Syndrome",
        'applyname': "Tourette Syndrome",
    },
}
